import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

import { Textarea, Checkbox } from '@peracto/peracto-ui'

const OrderHistoryForm = ({ onSubmitForm }) => {
    const [showForm, setShowForm] = useState(false)

    const initialValues = {
        type: 'note',
        detail: '',
        private: false,
        userEmailSent: false,
    }

    return (
        <>
            <div className="d-flex justify-content-end">
                <button className="btn btn-outline-primary" onClick={() => setShowForm(!showForm)}>
                    <FontAwesomeIcon icon={showForm ? faTimes : faEdit} className="mr-2" />
                    {showForm ? 'Cancel' : 'Add Note'}
                </button>
            </div>

            {showForm && (
                <Formik
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={onSubmitForm}
                >
                    <FormikForm>
                        <Textarea name="detail" label="Detail" />
                        <Checkbox name="userEmailSent" label="Email Customer" />
                        <Checkbox
                            name="private"
                            label="Internal Only"
                            tooltip="Internal notes will not be displayed within the customer order view."
                        />
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-success">
                                Submit
                            </button>
                        </div>
                    </FormikForm>
                </Formik>
            )}
        </>
    )
}

export default OrderHistoryForm
