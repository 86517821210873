import React, { useEffect, useState } from "react";
import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { Link, useParams, Redirect } from "react-router-dom";
import {
    GET_ONE,
    UPDATE,
    CREATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import sharedYupFields from "../sharedYupFields";
import { formatDataForApi, formatDataForForm } from "../util";

function formatDuplicatedData(data) {
    const dataClone = { ...data };

    dataClone.alias = data?.alias?.length > 0 ? `copy-of-${data.alias}` : "";
    dataClone.name = `Copy of ${data.name}`;
    delete dataClone["@id"];
    delete dataClone["@context"];
    delete dataClone["@type"];
    delete dataClone["id"];
    delete dataClone["createdAt"];
    delete dataClone["modifiedAt"];

    return dataClone;
}

const LocationsEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Location">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link className="btn btn-outline-primary" to="/locations">
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Locations
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const LocationsEdit = ({ LocationsForm }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [formData, setFormData] = useState(false);
    const [redirect, setRedirect] = useState("");
    const { locationId } = useParams();

    const schema = getSchemaFromResource(getResource("locations")).shape({
        ...sharedYupFields,
        slug: yup
            .string()
            .when("locationPageStatus", (locationPageStatus, schema) => {
                return locationPageStatus === "active"
                    ? schema.required()
                    : schema.notRequired();
            }),
    });

    const onSubmit = async (data, actions) => {
        try {
            await client(UPDATE, "locations", {
                id: `locations/${locationId}`,
                data: formatDataForApi(data),
            });

            actions.setSubmitting(false);

            fetchLocation();
            toast.success("Location successfully updated!");
        } catch (e) {
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                e.error.body.violations.map((error) => {
                    return actions.setFieldError(
                        error.propertyPath,
                        error.message
                    );
                });
            }
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    const onDuplicate = async (data) => {
        const formattedData = formatDuplicatedData(data);
        try {
            const response = await client(CREATE, "locations", {
                data: formattedData,
            });

            setRedirect(response.data.id);
            toast.success("Location successfully duplicated!");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const fetchLocation = async () => {
        try {
            const { data: locationData, response: locationResponse } =
                await client(GET_ONE, "locations", {
                    id: `locations/${locationId}`,
                });

            const formattedData = formatDataForForm(locationData);

            setFormData(formattedData);

            setLoading(false);

            if (locationResponse.status === 404) {
                setRedirect("/locations");
            }
        } catch (e) {
            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setLoading(false);
            setRedirect("/locations");
        }
    };

    useEffect(() => {
        fetchLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/locations"
                message="The location you're looking for could not be found"
                buttonLabel="Go to Locations"
            />
        );
    }

    return loading ? (
        <LocationsEditContainer>
            <div className="card">
                <div className="card-body">Loading...</div>
            </div>
        </LocationsEditContainer>
    ) : (
        <>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>{formData.name || "Location"} | Edit | Peracto</title>
            </Helmet>

            <div className="form-container">
                <Heading name="Edit Location">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            to="/locations"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Locations
                        </Link>
                    </div>
                </Heading>
                <LocationsForm
                    modeEdit
                    data={formData}
                    onSubmit={onSubmit}
                    onDuplicate={onDuplicate}
                    schema={schema}
                />
            </div>
        </>
    );
};

export default LocationsEdit;
