

export const primary = {
    accentColor: 'rgba(0,152,64,1)',
    textColor: 'rgba(0,152,64,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: { color: 'white' },
}

export const secondary = {
    textColor: 'rgba(0,77,165,1)',
    accentColor: 'rgba(0,77,165,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: { color: 'white' },
}

export const success = {
    accentColor: 'rgba(45,178,0,1)',
    textColor: 'rgba(45, 178, 0,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: { color: 'brand.white' },
}
