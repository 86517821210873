import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

const PostcodeChargeInput = ({ push }) => {
    const initialPostcodeCharge = {
        postcodeArea: '',
        districtStart: '',
        districtEnd: '',
        cost: '',
    }

    const [postcodeCharge, setPostcodeCharge] = useState({ ...initialPostcodeCharge })

    return (
        <div className="form-group">
            <div className="form-row">
                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeCharge.postcodeArea}
                        placeholder="Postcode Area"
                        maxLength="2"
                        onChange={e =>
                            setPostcodeCharge({
                                ...postcodeCharge,
                                postcodeArea: e.target.value,
                            })
                        }
                        onBlur={() => {
                            if (!isNaN(postcodeCharge.postcodeArea)) {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    postcodeArea: '',
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeCharge.districtStart}
                        placeholder="District Start"
                        type="number"
                        min="0"
                        max="99"
                        maxLength="2"
                        onChange={e =>
                            setPostcodeCharge({
                                ...postcodeCharge,
                                districtStart: e.target.value,
                            })
                        }
                        onBlur={() => {
                            if (postcodeCharge.districtStart > 99) {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtStart: 99,
                                })
                            }
                            if (postcodeCharge.districtStart === '') {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtEnd: '',
                                })
                            }
                            if (
                                postcodeCharge.districtStart !== '' &&
                                postcodeCharge.districtEnd !== '' &&
                                parseInt(postcodeCharge.districtEnd) <
                                    parseInt(postcodeCharge.districtStart)
                            ) {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtEnd: parseInt(postcodeCharge.districtStart) + 1,
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeCharge.districtEnd}
                        placeholder="District End"
                        type="number"
                        min="0"
                        max="99"
                        maxLength="2"
                        onChange={e =>
                            setPostcodeCharge({
                                ...postcodeCharge,
                                districtEnd: e.target.value,
                            })
                        }
                        onBlur={() => {
                            if (postcodeCharge.districtStart === '') {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtEnd: '',
                                })
                            }

                            if (postcodeCharge.districtEnd > 99) {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtEnd: 99,
                                })
                            }

                            if (
                                postcodeCharge.districtEnd !== '' &&
                                parseInt(postcodeCharge.districtEnd) <=
                                    parseInt(postcodeCharge.districtStart)
                            ) {
                                setPostcodeCharge({
                                    ...postcodeCharge,
                                    districtEnd: parseInt(postcodeCharge.districtStart) + 1,
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <div className="input-group-prepend">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text" data-testid="prefix">
                                    {'\u00A3'} / {'\u20AC'}
                                </div>
                            </div>

                            <input
                                className="form-control"
                                value={postcodeCharge.cost}
                                placeholder="Price"
                                type="number"
                                onChange={e =>
                                    setPostcodeCharge({
                                        ...postcodeCharge,
                                        cost: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-12 text-right">
                    <button
                        className="btn btn-link px-0"
                        type="button"
                        onClick={() => {
                            // prevent adding the rule if no postcode area
                            if (postcodeCharge.postcodeArea) {
                                push({
                                    ...postcodeCharge,
                                })

                                setPostcodeCharge({ ...initialPostcodeCharge })
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Charge
                    </button>
                </div>
            </div>
        </div>
    )
}

PostcodeChargeInput.propTypes = {
    push: PropTypes.func.isRequired,
}

export default PostcodeChargeInput
