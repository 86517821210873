import React from 'react'
import dayjs from 'dayjs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faLockOpenAlt } from '@fortawesome/pro-regular-svg-icons/faLockOpenAlt'

import startCase from 'lodash/startCase'

const OrderHistoryList = ({ history = [] }) => {
    if (history.length === 0) {
        return <p className="py-4 text-center m-0">This order has no history</p>
    } else {
        return (
            <table className="table table-sm">
                <colgroup>
                    <col width="15%" />
                    <col width="10%" />
                    <col width="50%" />
                    <col width="15%" />
                    <col width="5%" />
                    <col width="5%" />
                </colgroup>

                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Detail</th>
                        <th>Change By</th>
                        <th>Emailed</th>
                        <th>Private</th>
                    </tr>
                </thead>

                <tbody>
                    {history.map(historyData => (
                        <tr key={historyData['@id']}>
                            <td>
                                {dayjs(new Date(historyData.createdAt)).format(
                                    'YYYY-MM-DD HH:mm:ss'
                                )}
                            </td>
                            <td>{startCase(historyData.type)}</td>
                            <td>{historyData.detail}</td>
                            <td>{historyData.changeByDetail}</td>
                            <td className="text-center">
                                <span
                                    className={
                                        historyData.userEmailSent ? 'text-success' : 'text-danger'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={historyData.userEmailSent ? faCheck : faTimes}
                                    />
                                </span>
                            </td>
                            <td className="text-center">
                                <FontAwesomeIcon
                                    icon={historyData.private ? faLockAlt : faLockOpenAlt}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}

export default OrderHistoryList
