import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../UserForm";

const UserAdd = ({ UserForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState();

    const schema = yup.object().shape({
        user: getSchemaFromResource(getResource("users")).shape({
            firstName: yup.string().required("First Name is required."),
            lastName: yup.string().required("Last Name is required."),
            email: yup.string().required("Email is required."),
            title: yup.string().nullable(),
            telephone: yup.string().nullable(),
        }),
    });

    const onSubmit = async (data, actions) => {
        try {
            const response = await client(CREATE, "users", {
                data: {
                    ...data.user,
                    dateOfBirth: data.user?.dateOfBirth
                        ? dayjs(data.user.dateOfBirth).format("YYYY-MM-DD")
                        : null,
                },
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.map((error) =>
                    actions.setFieldError(
                        `user.${error.propertyPath}`,
                        error.message
                    )
                );
            } else {
                setRedirect(response.data.id);
                toast.success("User successfully added!");
                actions.setSubmitting(false);
            }
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);

            return e?.error?.body?.violations?.map((error) =>
                actions.setFieldError(
                    `user.${error.propertyPath}`,
                    error.message
                )
            );
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>Users | Add | Peracto</title>
            </Helmet>

            <div className="form-container">
                <Heading name="Add User">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link className="btn btn-outline-primary" to="/users">
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Users
                        </Link>
                    </div>
                </Heading>

                <UserForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                    testId="add"
                    values={{
                        user: {
                            title: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                            telephone: "",
                            businessTelephone: "",
                            dateOfBirth: "",
                            enabled: false,
                            roles: [],
                        },
                    }}
                />
            </div>
        </>
    );
};

export default UserAdd;
