import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

const PostcodeRuleInput = ({ push }) => {
    const initialPostcodeRule = {
        postcodeArea: '',
        districtStart: '',
        districtEnd: '',
    }

    const [postcodeRule, setPostcodeRule] = useState({ ...initialPostcodeRule })

    return (
        <div className="form-group">
            <div className="form-row">
                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeRule.postcodeArea}
                        placeholder="Postcode Area"
                        maxLength="2"
                        onChange={e =>
                            setPostcodeRule({
                                ...postcodeRule,
                                postcodeArea: e.target.value,
                            })
                        }
                        onBlur={() => {
                            if (!isNaN(postcodeRule.postcodeArea)) {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    postcodeArea: '',
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeRule.districtStart}
                        placeholder="District Start"
                        onChange={e =>
                            setPostcodeRule({
                                ...postcodeRule,
                                districtStart: e.target.value,
                            })
                        }
                        type="number"
                        min="0"
                        max="99"
                        maxLength="2"
                        onBlur={() => {
                            if (postcodeRule.districtStart > 99) {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtStart: 99,
                                })
                            }
                            if (postcodeRule.districtStart === '') {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtEnd: '',
                                })
                            }
                            if (
                                postcodeRule.districtStart !== '' &&
                                postcodeRule.districtEnd !== '' &&
                                postcodeRule.districtEnd < postcodeRule.districtStart
                            ) {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtEnd: parseInt(postcodeRule.districtStart) + 1,
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3 mb-3 mb-md-0">
                    <input
                        className="form-control"
                        value={postcodeRule.districtEnd}
                        placeholder="District End"
                        min="0"
                        max="99"
                        maxLength="2"
                        type="number"
                        onChange={e =>
                            setPostcodeRule({
                                ...postcodeRule,
                                districtEnd: e.target.value,
                            })
                        }
                        onBlur={() => {
                            if (postcodeRule.districtStart === '') {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtEnd: '',
                                })
                            }

                            if (postcodeRule.districtEnd > 99) {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtEnd: 99,
                                })
                            }

                            if (
                                postcodeRule.districtEnd !== '' &&
                                parseInt(postcodeRule.districtEnd) <=
                                    parseInt(postcodeRule.districtStart)
                            ) {
                                setPostcodeRule({
                                    ...postcodeRule,
                                    districtEnd: parseInt(postcodeRule.districtStart) + 1,
                                })
                            }
                        }}
                    />
                </div>

                <div className="col-12 col-lg-3">
                    <button
                        className="btn btn-link px-0"
                        type="button"
                        onClick={() => {
                            // prevent adding the rule if no postcode area
                            if (postcodeRule.postcodeArea) {
                                push({
                                    ...postcodeRule,
                                })

                                setPostcodeRule({ ...initialPostcodeRule })
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Rule
                    </button>
                </div>
            </div>
        </div>
    )
}

PostcodeRuleInput.propTypes = {
    push: PropTypes.func.isRequired,
}

export default PostcodeRuleInput
