import React, {useState, useEffect, useCallback} from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { GET_LIST, useClient } from '@peracto/client'
import ConditionsRow from './ConditionsRow'

const CONDITION_TYPES = [
    { label: 'Basket Subtotal', value: 'basket.subtotal', dataType: 'int' },
    { label: 'Basket Delivery Subtotal', value: 'basket.delivery_subtotal', dataType: 'int' },
    { label: 'Basket Weight', value: 'basket.weight', dataType: 'int' },
    { label: 'User Customer Group', value: 'basket.user_customer_group_name', dataType: 'string' },
]

const Conditions = ({ name }) => {

    const [initialAttributes, setInitialAttributes] = useState()

    const { client } = useClient()

    const defaultCondition = {
        value: '1',
        condition: 'basket.subtotal',
        operation: '=',
    }

    const fetchAttributes = useCallback(async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'attributes', {
            id: 'attributes',
            label: inputValue,
            filter: {
                allowedInShipping: true,
            },
            pagination: {
                perPage: 1000,
            },
        })

        const values = data.map(attr => ({
            label: attr.label,
            value: `attribute.${attr.code}`,
            dataType: attr.dataType,
        }))

        const mergedData = [
            ...CONDITION_TYPES.filter(type => {
                return type.label.toLowerCase().includes(inputValue.toLowerCase())
            }),
            ...values,
        ]

        if (!initialAttributes) {
            setInitialAttributes(mergedData)
        }

        return mergedData
    }, [client, initialAttributes])

    useEffect(() => {
        fetchAttributes()
    }, [fetchAttributes])

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => {
                            return (
                                <div className="form-group">
                                    {field.value && field.value.length > 0 ? (
                                        <>
                                            <p className="mt-3">
                                                The following must be true to attempt to apply the
                                                condition.{' '}
                                            </p>

                                            {field.value.map((option, index) => (
                                                <div key={`requirement_${index}`}>
                                                    <ConditionsRow
                                                        {...{
                                                            index,
                                                            form,
                                                            field,
                                                            remove,
                                                        }}
                                                        fetchAttributes={fetchAttributes}
                                                        attributes={initialAttributes}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <p className="py-3 my-3 text-center">
                                            No conditions have been applied to this shipping service
                                        </p>
                                    )}

                                    <div className="d-flex align-items-center justify-content-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-link px-0 mt-2"
                                            onClick={() => {
                                                push(defaultCondition)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                            Add New Condition
                                        </button>
                                    </div>
                                </div>
                            )
                        }}
                    </FieldArray>
                </>
            )}
        </Field>
    )
}

export default Conditions
