import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import dayjs from 'dayjs'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import upperFirst from 'lodash/upperFirst'
import * as S from './styled'

const ShippingDateRange = ({ push, type }) => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    return (
        <div className="form-group mb-0 mr-2">
            <S.DatePickerContainer className="form-row">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <DatePicker
                        className="datepicker__start-date form-control"
                        placeholderText="Start Date"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={date => {
                            setStartDate(date)
                            if (!endDate || dayjs(date) > dayjs(endDate)) {
                                setEndDate(date)
                            }
                        }}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <DatePicker
                        className="datepicker__end-date form-control"
                        placeholderText="End Date"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
                </div>
                <div className="col-12 col-md-4">
                    <button
                        className="btn btn-link px-0"
                        type="button"
                        onClick={() => {
                            if (!startDate || !endDate) return
                            push({
                                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                                endDate: dayjs(endDate).format('YYYY-MM-DD'),
                            })

                            setStartDate(null)
                            setEndDate(null)
                        }}
                        disabled={!startDate || !endDate}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add {upperFirst(type)}
                    </button>
                </div>
            </S.DatePickerContainer>
        </div>
    )
}

ShippingDateRange.propTypes = {
    push: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
}

export default ShippingDateRange
