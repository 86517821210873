import React from 'react'
import { Select, Input } from '@peracto/peracto-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { useSettings } from '@peracto/peracto-hooks'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'

const CURRENCY_CONDITIONS = ['basket.subtotal']

const OPERATION_TYPES = [
    { label: 'Equals', value: '=' },
    { label: 'Not equal to', value: '!=' },
]

const ATTRIBUTE_OPERATION_TYPES = [
    { label: 'All Values Match', value: 'all_values_match' },
    { label: 'No Values Match', value: 'no_values_match' },
    { label: 'Some Values Match', value: 'some_values_match' },
]

const INTEGER_OPERATION_TYPES = [
    { label: 'Greater than', value: '>' },
    { label: 'Less than', value: '<' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Less than or equal', value: '<=' },
]

const STRING_OPERATION_TYPES = [
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'does_not_contain' },
]

const ConditionsRow = ({ index, form, field, remove, attributes = [], fetchAttributes }) => {
    const type = form?.values[field.name][index]['condition']

    const value = attributes.find(attr => attr.value === type)

    let operationTypes = []

    switch (value?.dataType) {
        case 'array':
            operationTypes = ATTRIBUTE_OPERATION_TYPES
            break
        case 'int':
            operationTypes = [...OPERATION_TYPES, ...INTEGER_OPERATION_TYPES]
            break
        case 'string':
            operationTypes = [...OPERATION_TYPES, ...STRING_OPERATION_TYPES]
            break
        default:
            operationTypes = [...OPERATION_TYPES]
    }

    const { values: p_values } = useSettings()

    const debouncedFetchAttributes = debounce(fetchAttributes, 200)

    const WEIGHT_UNIT = p_values?.weight_unit

    return (
        <div key={`condition_${index}`}>
            <div className="row mb-1">
                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12 col-md-4">
                            <AsyncSelect
                                className="w-100"
                                classNamePrefix="list"
                                loadOptions={input => debouncedFetchAttributes(input)}
                                isSearchable={true}
                                onChange={option => {
                                    form.setFieldValue(
                                        `${field.name}.${index}.condition`,
                                        option.value
                                    )
                                    form.setFieldValue(
                                        `${field.name}.${index}.operation`,
                                        operationTypes[0].value
                                    )
                                }}
                                value={value}
                                placeholder="Search for Attributes to add..."
                                noOptionsMessage={({ inputValue }) => {
                                    if (inputValue.length > 0) {
                                        return `No results found for '${inputValue}'.`
                                    } else {
                                        return 'Enter text to begin searching.'
                                    }
                                }}
                            />
                        </div>

                        <div className="col-12 col-md-4">
                            <Select
                                name={`${field.name}.${index}.operation`}
                                options={operationTypes}
                            />
                        </div>

                        <div className="col-12 col-md-3">
                            <Input
                                type={value?.dataType === 'int' ? 'number' : 'text'}
                                name={`${field.name}.${index}.value`}
                                min="0"
                                prefix={
                                    CURRENCY_CONDITIONS.includes(type)
                                        ? `${'\u00A3'} / ${'\u20AC'}`
                                        : null
                                }
                                step={CURRENCY_CONDITIONS.includes(type) ? 0.01 : 1}
                                suffix={type === 'basket.weight' && WEIGHT_UNIT}
                            />
                        </div>

                        <div className="col-12 col-md-1 text-right">
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                    remove(index)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConditionsRow
