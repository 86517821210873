import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Form,
    Checkbox,
    Group,
    Input,
    Tags,
    Select,
    Modal,
    DateInput,
    FormActions,
} from "@peracto/peracto-ui";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";

import AddressForm from "./AddressForm";
import * as S from "./styled";

import { CREATE, DELETE, GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";

import { roleValues } from "@peracto/peracto-user";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const UserForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onSaveAddress,
    testId,
    ...props
}) => {
    const [showUserDialog, setShowUserDialog] = useState(false);

    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
    const [visibleFields, setVisibleFields] = useState({});
    const [customerGroups, setCustomerGroups] = useState([]);
    const [redirect, setRedirect] = useState();
    const { client } = useClient();

    const config = useConfig();
    const { user } = config.get("features", {});

    const titles = [
        { label: "Mr", value: "Mr" },
        { label: "Mrs", value: "Mrs" },
        { label: "Miss", value: "Miss" },
        { label: "Ms", value: "Ms" },
        { label: "Mx", value: "Mx" },
    ];

    const roles = [
        { label: "Admin", value: "ROLE_ADMIN" },
        { label: "User", value: "ROLE_USER" },
    ];

    const onDelete = async () => {
        try {
            await client(DELETE, "users", {
                id: values.user.id,
            });

            toast.success("User deleted successfully!");
            setRedirect("/users");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const onResetPassword = async () => {
        setSendingPasswordReset(true);
        try {
            await client(CREATE, "users/reset-password", {
                data: {
                    email: values.user.email,
                },
            });

            setShowPasswordReset(false);
            setSendingPasswordReset(false);
            toast.success("Password reset email sent!");
        } catch (e) {
            console.error(e);
            setSendingPasswordReset(false);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const fetchCustomerGroups = async (inputValue = "") => {
        const { data } = await client(GET_LIST, "customer-groups", {
            id: "customer-groups",
            label: inputValue,
        });

        const values = data.map((val) => ({
            label: val.name,
            value: val.id,
        }));

        setCustomerGroups(values);

        return values;
    };

    useEffect(() => {
        fetchCustomerGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div data-testid={testId}>
                {redirect && <Redirect to={redirect} />}

                {mode === MODE_EDIT && (
                    <FormActions>
                        <>
                            <button
                                className="btn p-0 mr-2 btn-link"
                                onClick={() => setShowPasswordReset(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="mr-2"
                                />
                                Reset Password
                            </button>

                            <button
                                className="btn btn-link p-0 text-danger"
                                onClick={() => setShowUserDialog(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                />
                                Delete User
                            </button>
                        </>
                    </FormActions>
                )}

                <Form autoComplete="off" values={values} {...props}>
                    <Group key="customer" id="customer" name="Customer">
                        {mode === MODE_EDIT && (
                            <div className="d-flex justify-content-between align-items-start">
                                <div>
                                    {!visibleFields.customer && (
                                        <S.Address>
                                            <p className="address-name">
                                                {values.user.firstName}{" "}
                                                {values.user.lastName}
                                            </p>
                                            <p>{values.user.email}</p>
                                            <p>{values.user.telephone}</p>
                                            <p>
                                                {values.user.roles.map(
                                                    (val, idx) => (
                                                        <span
                                                            key={`role-${idx}`}
                                                        >
                                                            {idx > 0
                                                                ? ", "
                                                                : ""}
                                                            {roleValues[val]}
                                                        </span>
                                                    )
                                                )}
                                            </p>
                                        </S.Address>
                                    )}
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-link p-0"
                                    onClick={() => {
                                        setVisibleFields({
                                            ...visibleFields,
                                            customer: !visibleFields.customer,
                                        });
                                    }}
                                >
                                    {visibleFields.customer ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                className="mr-2"
                                            />
                                            Close
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className="mr-2"
                                            />
                                            Edit
                                        </>
                                    )}
                                </button>
                            </div>
                        )}

                        {(mode === MODE_ADD || visibleFields.customer) && (
                            <>
                                <Select
                                    name="user.title"
                                    label="Title"
                                    options={titles}
                                    placeholder="Select a title"
                                    testId="title"
                                    testIdItems="title__item"
                                    testIdIndex={0}
                                />
                                <Input
                                    name="user.firstName"
                                    label="First name"
                                    required
                                    autoComplete="first-name"
                                    testId="firstname"
                                />
                                <Input
                                    name="user.lastName"
                                    label="Last name"
                                    required
                                    autoComplete="last-name"
                                    testId="lastname"
                                />
                                <Input
                                    name="user.email"
                                    label="Email"
                                    required
                                    autoComplete="email"
                                    testId="email"
                                />
                                <Input
                                    name="user.telephone"
                                    label="Telephone"
                                    testId="telephone"
                                />

                                <Input
                                    name="user.mobile"
                                    label="Mobile Number"
                                    testId="mobile"
                                />

                                <Input
                                    name="user.businessTelephoneNumber"
                                    label="Business Telephone"
                                    testId="business-telephone"
                                />

                                <DateInput
                                    name="user.dateOfBirth"
                                    label="Date of Birth"
                                    testId="date-of-birth"
                                    fullWidth={true}
                                />

                                <Tags
                                    name="user.roles"
                                    label="Roles"
                                    options={roles}
                                    testId="roles"
                                    testIdItems="roles__item"
                                />

                                {customerGroups && customerGroups?.length > 0 && (
                                    <Field name="user.customerGroup">
                                        {({ field, form }) => (
                                            <div className="form-group">
                                                <label>Customer Group</label>
                                                <Select
                                                    name={field.name}
                                                    className="w-100"
                                                    isSearchable={true}
                                                    onChange={(option) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            option.value
                                                        );
                                                    }}
                                                    options={customerGroups}
                                                    placeholder="Search for Customer Groups..."
                                                    testId="customer-groups"
                                                    testIdItems="customer-groups__item"
                                                    testIdIndex={1}
                                                    allowClear={true}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                )}

                                <Input
                                    name="user.creditLimit"
                                    type="number"
                                    step="0.01"
                                    label="Credit Limit"
                                    testId="credit-limit"
                                />

                                {user?.companySettings && (
                                    <>
                                        <hr />

                                        <Input
                                            name="user.accountNumber"
                                            label="Account Number"
                                            testId="account-number"
                                            required
                                        />
                                    </>
                                )}

                                <hr />

                                <Checkbox
                                    name="user.enabled"
                                    label="Customer Enabled"
                                    testId="enabled"
                                />

                                {user?.userSettings && (
                                    <>
                                        <hr />

                                        <h6>User Settings</h6>

                                        <Select
                                            name="user.currency"
                                            label="Currency"
                                            options={[
                                                { label: "GBP", value: "GBP" },
                                            ]}
                                            placeholder="Select a currency"
                                            testId="currency"
                                            testIdItems="currency__item"
                                            testIdIndex={0}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </Group>
                </Form>

                {mode === MODE_EDIT && (
                    <>
                        <AddressForm
                            values={values}
                            visibleFields={visibleFields}
                            setVisibleFields={setVisibleFields}
                            setFormData={setFormData}
                            titles={titles}
                            onSaveAddress={onSaveAddress}
                            {...props}
                        />

                        <Modal
                            isVisible={showUserDialog}
                            title="Delete User"
                            close={() => setShowUserDialog(false)}
                            buttons={[
                                {
                                    type: "btn-outline-secondary",
                                    text: "Close",
                                    action: () => setShowUserDialog(false),
                                },
                                {
                                    type: "btn-danger",
                                    text: "Delete User",
                                    action: () => onDelete(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="d-block mb-4"
                            />
                            Are you sure you would like to permanently delete
                            the account of {values.user.email}? Deleted users
                            cannot be recovered.
                        </Modal>

                        <Modal
                            isVisible={showPasswordReset}
                            title="Reset Password"
                            close={() => setShowPasswordReset(false)}
                            buttons={[
                                {
                                    type: "btn-outline-secondary",
                                    text: "Cancel",
                                    action: () => setShowPasswordReset(false),
                                },
                                {
                                    type: "btn-success",
                                    text: sendingPasswordReset
                                        ? "Sending Email..."
                                        : "Send Email",
                                    disabled: sendingPasswordReset,
                                    action: () => onResetPassword(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="d-block mb-4"
                            />
                            Are you sure you would like to generate a reset
                            password email for {values.user.email}?
                        </Modal>
                    </>
                )}
            </div>
        </>
    );
};

UserForm.displayName = "UserForm";
UserForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func,
    countries: PropTypes.array,
};

export default UserForm;
