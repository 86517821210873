import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brandBlue: {
            500: "#164397", // used for input elements
        },
        brand: {
            "Web Blue": "#164397",
            White: "#ffffff",
            Fencing: "#8e58a4",
            Decking: "#92c947",
            Insulation: "#f99b27",
            "Sheet Materials": "#f47320",
            "Bricks and Blocks": "#044a42",
            Lintels: "#f99b27",
            Aggregates: "#aba49e",
            Drainage: "#0099d7",
            Roofing: "#6b9d2c",
            Hardware: "#fcbe11",
            "Landscaping and Paving": "#00adef",
            "Building Materials": "#dd432b",
            "Timber & Sheet": "#f47320",
            "Doors & Flooring": "#fcbe11",
            "Landscaping & Paving": "#00adef",
            "Plumbing & Heating": "#ef0099",
            Bathrooms: "#a3e1ee",
            black: "#222222",
            darkGrey: "#575757",
            grey: "#d8d8d8",
            lightGrey: "#f3f3f3",
            white: "#FFFFFF",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "Roboto, sans-serif",
        body: "Roboto, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const listStyles = css`
    ul:not([class^="DayPicker"]) {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                height: 10px;
                width: 10px;
                margin-right: 10px;
                background-color: ${EditorTheme.colors.brand["Web Blue"]};
            }
        }
    }
`;

const headings = css`
    h1 {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
        font-weight: 700;

        @media (max-width: 478px) {
            font-size: 24px;
            line-height: 30px;
        }

        &.heading1-large {
            font-size: 60px;
            line-height: 64px;

            @media (max-width: 478px) {
                font-size: 30px;
                line-height: 32px;
            }
        }
    }

    h2 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 15px;
        font-weight: 700;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;

        @media (max-width: 478px) {
            font-size: 20px;
            line-height: 26px;
        }

        &.heading3-paragraph {
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }

    h5 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
    }

    h6 {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
    }

    p {
        margin: 0;
    }
`;

const table = css`
    .table {
        td {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid ${EditorTheme.colors.brand.grey};
        }
    }
`;

const focusVisible = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

const reactPage = css`
    .ory-row {
        a {
            text-decoration: underline;
        }
    }
`;

const inputs = css`
    input,
    select,
    textarea {
        font-size: 13.6px;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
            color: ${EditorTheme.colors.brand.grey};
        }

        &:hover:not([id^="react-select"]) {
            border-color: ${EditorTheme.colors.brand.black} !important;
        }

        &:focus:not([id^="react-select"]) {
            border-color: ${EditorTheme.colors.brand["Web Blue"]} !important;
        }
    }

    select {
        + div > svg {
            color: ${EditorTheme.colors.brand["Web Blue"]} !important;
        }
    }

    .react-dropdown-select-content {
        input,
        select,
        textarea {
            &:focus {
                border-color: none !important;
                box-shadow: none !important;
            }
        }
    }

    input[type="radio"] {
        + div {
            transition: border-color 0.2s;
            border-color: ${EditorTheme.colors.brand.grey} !important;
            border-width: 1px !important;
        }

        &:hover {
            + div {
                border-color: ${EditorTheme.colors.brand.darkGrey} !important;
            }
        }
        &:checked {
            + div {
                background-color: ${EditorTheme.colors.brand[
                    "Web Blue"
                ]} !important;
                border-color: ${EditorTheme.colors.brand[
                    "Web Blue"
                ]} !important;
                box-shadow: inset 0px 0px 0px 2px
                    ${EditorTheme.colors.brand.white} !important;

                span {
                    display: none;
                }
            }
        }
    }
`;

export const GlobalStyles = css`
    ${listStyles}
    ${headings}
    ${table}
    ${focusVisible}
    ${reactPage}
    ${inputs}
`;
