import React from "react";
import { Field, useFormikContext } from "formik";
import {
    TimeField,
    Select,
    Group,
    Input,
    Textarea,
    Checkbox,
    Tags,
    RichText,
} from "@peracto/peracto-ui";
import LocationImages from "./LocationImages";
import { useSettings } from "@peracto/peracto-hooks";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";
import { DEFAULT_LOCATION_TYPES } from "../util";

export const LocationFormContent = ({ countries, days }) => {
    const getCountryOptions = () =>
        countries
            ? countries.map((country) => ({
                  label: country.label,
                  value: country.iso3,
              }))
            : [];

    const getCountryDefaultValue = (field, options) => {
        const found = options.find((option) => {
            return option.label === field.value;
        });
        return found || { label: "", value: "" };
    };

    const { values } = useFormikContext();
    const { values: p_values } = useSettings();

    const locationsPath =
        p_values?.sub_urls?.sitemap_locations_sub_url || "branches";

    const locationFeatures =
        p_values?.location_features?.length > 0
            ? sortBy(p_values.location_features, ["display_order"])
            : [];

    const locationTypes =
        p_values?.location_types?.length > 0
            ? p_values.location_types.map((type) => {
                  return {
                      label: startCase(type.replace(/_/g, " ")),
                      value: type,
                  };
              })
            : DEFAULT_LOCATION_TYPES;

    return (
        <React.Fragment>
            <Group name="Location" id="location">
                <Input
                    label="Location Name"
                    required
                    name="name"
                    testId="location-name"
                />
                <Input
                    label="Location Heading"
                    help="The text to be displayed in the heading on the branch's page on the storefront. If no heading is supplied then the Location Name will be used instead."
                    name="heading"
                    testId="location-heading"
                />

                <Input
                    name="identifier"
                    testId="location-identifier"
                    label="Location Identifier"
                    required
                />

                <Tags
                    name="locationTypes"
                    label="Location Type"
                    options={locationTypes}
                    testId="location-type"
                />
                <Select
                    placeholder="Select Status"
                    name="locationStatus"
                    testId="location-status"
                    required
                    options={[
                        { label: "Open", value: "open" },
                        { label: "Closed", value: "closed" },
                        { label: "Opening Soon", value: "opening_soon" },
                        { label: "Closing Down", value: "closing_down" },
                    ]}
                    label="Status"
                />
                <Select
                    placeholder="Select Location Page"
                    name="locationPageStatus"
                    testId="location-page-status"
                    options={[
                        { label: "Active", value: "active" },
                        { label: "Inactive", value: "inactive" },
                    ]}
                    label="Location Page"
                    defaultValue={{ label: "Active", value: "active" }}
                />
                {(values.locationPageStatus === "active" ||
                    !values.locationPageStatus) && (
                    <Input
                        label="Alias"
                        required
                        name="slug"
                        testId="location-alias"
                        help="The alias is used to construct a unique URL for use on the storefront"
                        prefix={`${
                            process.env.REACT_APP_STOREFRONT_URL ||
                            window.location.origin
                        }/${locationsPath}/`}
                    />
                )}
            </Group>
            {(values.locationPageStatus === "active" ||
                !values.locationPageStatus) && (
                <Group name="Meta data" id="metaData">
                    <Input
                        name="metaTitle"
                        label="Title"
                        testId="location-meta-title"
                    />
                    <Textarea
                        name="metaDescription"
                        label="Description"
                        testId="location-meta-description"
                    />
                </Group>
            )}
            <Group name="Address" id="address">
                <Input
                    name="address1"
                    label="Address 1"
                    testId="location-address1"
                />
                <Input
                    name="address2"
                    label="Address 2"
                    testId="location-address2"
                />
                <Input
                    name="address3"
                    label="Address 3"
                    testId="location-address3"
                />
                <Input name="city" label="Town / City" testId="location-city" />
                <Input
                    name="county"
                    label="County / State"
                    testId="location-county"
                />
                <Input
                    name="postcode"
                    label="Postal Code"
                    testId="location-postcode"
                />
                <Field name="country">
                    {({ field }) => {
                        const options = getCountryOptions();
                        return countries && options ? (
                            <Select
                                defaultValue={getCountryDefaultValue(
                                    field,
                                    options
                                )}
                                name={field.name}
                                testId="location-country"
                                label="Country"
                                options={options}
                                placeholder="Select a country"
                                required
                            />
                        ) : (
                            <p className="help-text">Loading...</p>
                        );
                    }}
                </Field>

                <Input
                    name="latitude"
                    label="Latitude"
                    testId="location-latitude"
                    required
                />
                <Input
                    name="longitude"
                    label="Longitude"
                    testId="location-longitude"
                    required
                />
            </Group>
            <Group name="Contact information" id="contact-info">
                <Input
                    name="website"
                    label="Website"
                    testId="location-website"
                />
                <Input
                    name="telephone"
                    label="Telephone Number"
                    testId="location-telephone"
                />
                <Input name="email" label="Email" testId="location-email" />
            </Group>
            <Group name="Opening hours" id="opening-hours">
                <div className="form-row">
                    {days.map((day) => (
                        <React.Fragment key={day}>
                            <div className="col-12 col-md-6">
                                <TimeField
                                    name={`openingHours.${day.toLowerCase()}.opening`}
                                    label={`${day} Opening`}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <TimeField
                                    name={`openingHours.${day.toLowerCase()}.closing`}
                                    label={`${day} Closing`}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <RichText
                    label="Special Opening Hours"
                    name="specialOpeningHours"
                />
            </Group>
            <Group name="Content and images" id="content-and-images">
                <RichText
                    name="generalInformation"
                    label="General information"
                />
                <LocationImages />
            </Group>

            {locationFeatures?.length > 0 && (
                <Group name="Location Features" id="location-features">
                    {locationFeatures.map((feature) => (
                        <React.Fragment key={`feature_${feature.value}`}>
                            <Checkbox
                                name={`formLocationFeatures.${feature.value}`}
                                label={feature.label}
                            />
                        </React.Fragment>
                    ))}
                </Group>
            )}
        </React.Fragment>
    );
};
