/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
  GET_ONE,
  UPDATE,
  CREATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { useConfig } from "@peracto/peracto-config";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { useSettings } from "@peracto/peracto-hooks";
import cloneDeep from "lodash/cloneDeep";

import {
  formatDataForForm,
  formatDataForAPI,
  convertPricesToCurrency,
  convertPricesToIntegers,
  checkForDuplicateOptions,
  DEFAULT_DISPLAY_TYPES,
} from "../util";

const ProductEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Product">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            to="/products"
            data-testid="back-to-products"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Products
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const ProductEdit = ({ ProductForm }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();

  const { id } = useParams();
  const pathname = `/products/${id}`;

  const config = useConfig();
  const { products } = config.get("features", {});

  const additionalPriceTypes = products?.additionalPriceTypes ?? [];
  const showPricesAsCurrency = products?.showPricesAsCurrency ?? true;

  const [product, setProduct] = useState();

  const { values } = useSettings();

  const display = values?.display;

  const DISPLAY_OPTIONS = display?.length > 0 ? display : DEFAULT_DISPLAY_TYPES;

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const { data, response } = await client(GET_ONE, "products", {
        id: pathname,
      });

      let formatted = formatDataForForm(data, DISPLAY_OPTIONS);

      if (showPricesAsCurrency) {
        formatted = convertPricesToCurrency(formatted, additionalPriceTypes);
      }

      setProduct(formatted);
      setLoading(false);

      if (response.status === 404) {
        setRedirect("/products");
      }
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/products");
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [pathname]);

  const schema = getSchemaFromResource(getResource("products")).shape({
    mpn: yup.string().nullable(),
    gtin: yup.string().nullable(),
    "attribute-product_name": yup.string().required(),
    sku: yup.string().required(),
    slug: yup.string().required(),
    taxRate: yup.string().required(),
    bulkPrices: yup.array().of(
      yup.object().shape({
        value: yup.number(),
        quantity: yup.number(),
      })
    ),
  });

  const onSubmit = async (data, actions, redirect = null) => {
    const cloneData = cloneDeep(data);

    const hasDuplicates = checkForDuplicateOptions(data.options, actions);

    if (!hasDuplicates) {
      let formattedData;
      if (showPricesAsCurrency) {
        formattedData = convertPricesToIntegers(data, additionalPriceTypes);
        formattedData = formatDataForAPI({
          data: formattedData,
          displayFields: DISPLAY_OPTIONS,
          additionalPriceTypes,
        });
      } else {
        formattedData = formatDataForAPI({
          data,
          displayFields: DISPLAY_OPTIONS,
          additionalPriceTypes,
        });
      }

      const headers = {};
      if (formattedData?.saveAndIndex) {
        headers["peracto-index-product"] = true;
      }
      delete formattedData.saveAndIndex;

      actions.setSubmitting(false);

      try {
        await client(
          UPDATE,
          "products",
          {
            id: pathname,
            data: formattedData,
          },
          headers
        );
        actions.setSubmitting(false);
        if (redirect) {
          setRedirect(redirect);
        } else {
          fetchProduct();
        }
        toast.success("Product successfully updated!");
      } catch (e) {
        if (e?.error?.body?.violations?.length > 0) {
          // Display errors for invalid fields
          e.error.body.violations.map((error) => {
            actions.setFieldError(error.propertyPath, error.message);
          });
        }
        console.error(e);
        toast.error(
          e?.error?.body?.hasOwnProperty("hydra:description")
            ? e.error.body["hydra:description"]
            : "Whoops, there was a problem..."
        );
        actions.setValues(cloneData);
        actions.setSubmitting(false);
      }
    } else {
      actions.setSubmitting(false);
    }
  };

  const onDuplicate = async (data) => {
    let formattedData;

    if (showPricesAsCurrency) {
      formattedData = convertPricesToIntegers(data, additionalPriceTypes);
      formattedData = formatDataForAPI({
        data: formattedData,
        displayFields: DISPLAY_OPTIONS,
        isDuplicate: true,
        additionalPriceTypes,
      });
    } else {
      formattedData = formatDataForAPI({
        data,
        displayFields: DISPLAY_OPTIONS,
        isDuplicate: true,
        additionalPriceTypes,
      });
    }

    try {
      const response = await client(CREATE, "products", {
        data: formattedData,
      });

      // If the response is a URL, we need to extract the ID from it, otherwise we can use the ID directly (this is todo with API changes relating to the google search issues)
      if (response.data.id.includes("https://")) {
        const id = response.data.id.split("/products/").pop();
        setRedirect(`/products/${id}`);
      } else {
        setRedirect(response.data.id);
      }

      toast.success("Product successfully duplicated!");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  if (loading) {
    return (
      <ProductEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </ProductEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/products"
        message="The product you're looking for could not be found"
        buttonLabel="Go to Products"
      />
    );
  }

  return (
    <ProductEditContainer>
      {redirect && <Redirect push to={redirect} />}
      <Helmet>
        <title>
          {product?.["attribute-product_name"] || "Product"} | Edit | Peracto
        </title>
      </Helmet>

      <ProductForm
        values={product}
        onSubmit={onSubmit}
        onDuplicate={onDuplicate}
        schema={schema}
      />
    </ProductEditContainer>
  );
};

export default ProductEdit;
