import React from 'react'

import OrderHistoryList from './OrderHistoryList'
import OrderHistoryForm from './OrderHistoryForm'

const OrderHistory = ({ history, onSubmitForm }) => {
    return (
        <>
            <OrderHistoryList history={history} />
            <OrderHistoryForm onSubmitForm={onSubmitForm} />
        </>
    )
}

export default OrderHistory
