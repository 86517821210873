import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { Input } from '@peracto/peracto-ui'

import PostcodeChargeInput from './PostcodeChargeInput'

const PostcodeCharges = ({ name }) => (
    <Field name={name}>
        {({ field }) => (
            <FieldArray name={field.name}>
                {({ push, remove }) => (
                    <>
                        {field?.value?.length > 0 && (
                            <table className="table table-sm">
                                <colgroup>
                                    <col width="22%" />
                                    <col width="22%" />
                                    <col width="22%" />
                                    <col width="22%" />
                                    <col width="12%" />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th>Outcode</th>
                                        <th>District Start</th>
                                        <th>District End</th>
                                        <th>Cost</th>
                                        <th className="text-right">Remove</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {field.value.map((item, index) => (
                                        <tr key={`postcodeCharges-uk-${index}`}>
                                            <td>{item.postcodeArea}</td>
                                            <td>
                                                {item.districtStart > -1 ? item.districtStart : '-'}
                                            </td>

                                            <td>
                                                {item.districtEnd > -1 ? item.districtEnd : '-'}
                                            </td>
                                            <td>
                                                <Input
                                                    prefix={`${'\u00A3'} / ${'\u20AC'}`}
                                                    name={`${field.name}.${index}.cost`}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        <div className="form-group">
                            <label className="mb-1">Add Postcode Rule</label>
                            <PostcodeChargeInput push={push} />
                        </div>
                    </>
                )}
            </FieldArray>
        )}
    </Field>
)

PostcodeCharges.propTypes = {
    name: PropTypes.string.isRequired,
}

export default PostcodeCharges
