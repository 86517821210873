import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

import { Field, FieldArray } from 'formik'
import ReactSelect from 'react-select'
import { Input, Error } from '@peracto/peracto-ui'

const DeliveryCosts = ({ name, countries = [] }) => {
    const getCountryName = id => countries.find(country => country.id === id).label

    const filteredCountryList = (fieldValue = []) => {
        const selectedCountries = fieldValue.map(val => val.country)

        return countries
            .filter(country => !selectedCountries.includes(country.id))
            .map(country => ({
                label: country.label,
                value: country.id,
            }))
    }

    return (
        <Field name={name}>
            {({ form, field }) => (
                <>
                    {form.errors[field.name] && (
                        <div className="mb-2">
                            <Error error={form.errors[field.name]} />
                        </div>
                    )}
                    <FieldArray name={field.name}>
                        {({ push, remove }) => (
                            <>
                                {field?.value?.length > 0 && (
                                    <table className="table table-sm">
                                        <colgroup>
                                            <col width="45%" />
                                            <col width="45%" />
                                            <col width="10%" />
                                        </colgroup>

                                        <thead>
                                            <tr>
                                                <th>Country</th>
                                                <th>Cost</th>
                                                <th className="text-right">Remove</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {field.value.map((item, index) => (
                                                <tr key={`country-${index}`}>
                                                    <td>{getCountryName(item.country)}</td>
                                                    <td>
                                                        <Input
                                                            name={`costs[${index}].cost`}
                                                            type="number"
                                                            prefix={`${'\u00A3'} / ${'\u20AC'}`}
                                                            step="0.01"
                                                            min="0"
                                                        />
                                                    </td>
                                                    <td className="text-right">
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-danger"
                                                            onClick={() => {
                                                                remove(index)
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}

                                <div className="form-group">
                                    <label className="mb-1">Available Countries</label>
                                    <ReactSelect
                                        value={null}
                                        onChange={opt => {
                                            push({
                                                country: opt.value,
                                                cost: 0,
                                            })
                                        }}
                                        options={filteredCountryList(field.value)}
                                        classNamePrefix="peracto-select"
                                    />
                                </div>
                            </>
                        )}
                    </FieldArray>
                </>
            )}
        </Field>
    )
}

DeliveryCosts.propTypes = {
    name: PropTypes.string.isRequired,
    countries: PropTypes.array,
}

export default DeliveryCosts
