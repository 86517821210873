import React from 'react'
import { primary, secondary, success } from './variants'
import { Button as ChakraButton } from '@chakra-ui/core'
import { darken, lighten } from 'polished'

const variants = {
    primary,
    secondary,
    success,
}

export const Button = (props) => {
    const {
        children,
        variant = 'primary',
        variantStyle = 'solid',
        isDark,
        isDisabled,
        bold,
        underline,
        italic,
        uppercase,
        strikethrough,
        ...restProps
    } = props
    const hoverColor = {
        ...(!isDisabled && {
            _hover: {
                color: `white`,
                bg: lighten(0.05, variants[variant].accentColor),
            },
            _active: {
                bg: darken(0.05, variants[variant].accentColor),
            },
        }),
    }
    const textStyles = {
        ...(bold && { fontWeight: 'bold' }),
        ...(underline && { textDecoration: 'underline' }),
        ...(strikethrough && { textDecoration: 'line-through' }),
        ...(italic && { fontStyle: 'italic' }),
    }

    return (
        <ChakraButton
            variant={variantStyle}
            color={variants[variant].textColor}
            fontFamily={`body`}
            isDisabled={isDisabled}
            border='none'
            {...textStyles}
            {...{
                ...variants[variant][`sharedProps`],
                ...(variantStyle === `solid` && {
                    bg: variants[variant].accentColor,
                    ...hoverColor,
                    ...variants[variant].solidProps,
                }),
                ...(variantStyle === `outline` && {
                    ...hoverColor,
                    borderColor: variants[variant].accentColor,
                    ...variants[variant].outlineProps,
                }),
                ...(variantStyle === `ghost` && { ...hoverColor, ...variants[variant].ghostProps }),
                ...(variantStyle === `link` && {
                    padding: '',
                    ...{ ...(isDisabled && { _hover: { textDecoration: `none` } }) },
                    _active: { color: darken(0.05, variants[variant].textColor) },
                    ...variants[variant].linkProps,
                }),
                ...restProps,
            }}
        >
            {children}
        </ChakraButton>
    )
}

Button.defaultProps = {
    isDark: false,
    isDisabled: false,
    variant: 'primary',
    variantStyle: 'solid',
}
