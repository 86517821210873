import * as yup from "yup";

const nullableString = yup.string().nullable();

export default {
    metaDescription: nullableString,
    metaTitle: nullableString,
    address2: nullableString,
    address3: nullableString,
    county: nullableString,
    website: nullableString,
    specialOpeningHours: nullableString,
    generalInformation: nullableString,
    locationStatus: yup.string().required(),
    country: yup.string().required(),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
    identifier: yup
        .string()
        .required("Location Identifier is a required value")
        .nullable(),
};
