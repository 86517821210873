/* eslint-disable */
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import cloneDeep from "lodash/cloneDeep";

import { useConfig } from "@peracto/peracto-config";

import { Heading } from "@peracto/peracto-ui";
import { useSettings } from "@peracto/peracto-hooks";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../ProductForm";

import {
  formatDataForAPI,
  convertPricesToIntegers,
  checkForDuplicateOptions,
  DEFAULT_DISPLAY_TYPES,
} from "../util";

const ProductAdd = ({ ProductForm }) => {
  const { client, getResource } = useClient();
  const [redirect, setRedirect] = useState();

  const config = useConfig();
  const { products } = config.get("features", {});

  const {
    values: { display },
  } = useSettings();

  const DISPLAY_OPTIONS = display?.length > 0 ? display : DEFAULT_DISPLAY_TYPES;

  const additionalPriceTypes = products?.additionalPriceTypes ?? [];
  const showPricesAsCurrency = products?.showPricesAsCurrency ?? true;

  const schema = getSchemaFromResource(getResource("products")).shape({
    mpn: yup.string().nullable(),
    gtin: yup.string().nullable(),
    sku: yup.string().required(),
    slug: yup.string().required(),
    taxRate: yup.string().required(),
    "attribute-product_name": yup
      .string()
      .typeError("Product Name is a required field")
      .required(),
    bulkPrices: yup.array().of(
      yup.object().shape({
        value: yup.number(),
        quantity: yup.number(),
      })
    ),
  });

  const onSubmit = async (data, actions) => {
    const cloneData = cloneDeep(data);
    let formattedData;

    const hasDuplicates = checkForDuplicateOptions(data.options, actions);

    if (!hasDuplicates) {
      if (showPricesAsCurrency) {
        formattedData = convertPricesToIntegers(data, additionalPriceTypes);
        formattedData = formatDataForAPI({
          data: formattedData,
          displayFields: DISPLAY_OPTIONS,
          additionalPriceTypes,
        });
      } else {
        formattedData = formatDataForAPI({
          data,
          displayFields: DISPLAY_OPTIONS,
          additionalPriceTypes,
        });
      }

      const headers = {};

      if (formattedData?.saveAndIndex) {
        headers["peracto-index-product"] = true;
      }
      delete formattedData.saveAndIndex;

      try {
        const response = await client(
          CREATE,
          "products",
          {
            data: formattedData,
          },
          headers
        );

        if (response?.data?.id) {
          if (response.data.id.includes("https://")) {
            const id = response.data.id.split("/products/").pop();
            setRedirect(`/products/${id}`);
          } else {
            setRedirect(response.data.id);
          }
        } else {
          setRedirect("/products");
        }

        toast.success("Product successfully added!");
        actions.setSubmitting(false);
      } catch (e) {
        if (e?.error?.body?.violations?.length > 0) {
          // Display errors for invalid fields
          e.error.body.violations.map((error) => {
            actions.setFieldError(error.propertyPath, error.message);
          });
        }
        console.error(e);
        toast.error(
          e?.error?.body?.hasOwnProperty("hydra:description")
            ? e.error.body["hydra:description"]
            : "Whoops, there was a problem..."
        );
        actions.setValues(cloneData);
        actions.setSubmitting(false);
      }
    } else {
      actions.setSubmitting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Helmet>
        <title>Products | Add | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Add Product">
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <Link
              className="btn btn-outline-primary"
              to="/products"
              data-testid="back-to-products"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Products
            </Link>
          </div>
        </Heading>
        <ProductForm onSubmit={onSubmit} mode={MODE_ADD} schema={schema} />
      </div>
    </>
  );
};

export default ProductAdd;
