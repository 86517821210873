import React from "react";
import Sortable from "react-sortablejs";

import { Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

import { Textarea, File, SortableRow } from "@peracto/peracto-ui";

const LocationImages = () => {
    return (
        <Field name={`locationImages`}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => (
                            <>
                                <div className="form-group">
                                    <label className="mb-1">
                                        Location Images
                                    </label>
                                    {field?.value?.length > 0 && (
                                        <table className="table table-sm mb-1">
                                            <colgroup>
                                                <col width="5%" />
                                                <col width="10%" />
                                                <col width="30%" />
                                                <col width="40%" />
                                                <col width="15%" />
                                            </colgroup>

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Alt Text</th>
                                                    <th>Description</th>
                                                    <th className="text-right">
                                                        Remove
                                                    </th>
                                                </tr>
                                            </thead>

                                            <Sortable
                                                tag="tbody"
                                                onChange={(
                                                    order,
                                                    sortable,
                                                    evt
                                                ) => {
                                                    const values = [
                                                        ...field.value,
                                                    ];
                                                    const movedItem =
                                                        values[evt.oldIndex];
                                                    const to = evt.newIndex;
                                                    values.splice(
                                                        evt.oldIndex,
                                                        1
                                                    );
                                                    values.splice(
                                                        to,
                                                        0,
                                                        movedItem
                                                    );
                                                    form.setFieldValue(
                                                        field.name,
                                                        values
                                                    );
                                                }}
                                                options={{
                                                    animation: 100,
                                                    handle: ".handle",
                                                }}
                                            >
                                                {field.value.map(
                                                    (item, index) => (
                                                        <SortableRow
                                                            key={index}
                                                            data-id={index}
                                                        >
                                                            <td>
                                                                <span className="handle">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faGripLines
                                                                        }
                                                                    />
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                <img
                                                                    src={
                                                                        item.imageUrl
                                                                    }
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    title={
                                                                        item.altText
                                                                    }
                                                                    alt={
                                                                        item.altText
                                                                    }
                                                                />
                                                            </td>

                                                            <td>
                                                                <Textarea
                                                                    name={`locationImages.${index}.altText`}
                                                                    label=""
                                                                    placeholder="Alt Text"
                                                                />
                                                            </td>
                                                            <td>
                                                                <Textarea
                                                                    name={`locationImages.${index}.description`}
                                                                    label=""
                                                                    placeholder="Description"
                                                                />
                                                            </td>
                                                            <td className="text-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => {
                                                                        remove(
                                                                            index,
                                                                            1
                                                                        );
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTrash
                                                                        }
                                                                    />
                                                                </button>
                                                            </td>
                                                        </SortableRow>
                                                    )
                                                )}
                                            </Sortable>
                                        </table>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <File
                                                    name={`location_image_input`}
                                                    placeholder={`Add a new image to the location`}
                                                    onSelectCallback={(
                                                        value
                                                    ) => {
                                                        if (value.length > 0) {
                                                            push({
                                                                imageUrl: value,
                                                                altText: value,
                                                                description: "",
                                                                type: "image",
                                                            });
                                                        }
                                                    }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();

                                                            if (
                                                                e.target.value
                                                                    .length > 0
                                                            ) {
                                                                push({
                                                                    imageUrl:
                                                                        e.target
                                                                            .value,
                                                                    altText:
                                                                        e.target
                                                                            .value,
                                                                    description:
                                                                        "",
                                                                    type: "image",
                                                                });

                                                                e.target.value =
                                                                    "";
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </FieldArray>
                </>
            )}
        </Field>
    );
};

export default LocationImages;
