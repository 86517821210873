import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import {
    Form,
    FormActions,
    Group,
    Input,
    Select,
    Textarea,
    Checkbox,
    Modal,
    Radio,
} from '@peracto/peracto-ui'
import { Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'

import Conditions from './Conditions'
import DateManagement from './DateManagement'
import DeliveryCosts from './DeliveryCosts'
import PostcodeCharges from './PostcodeCharges'
import PostcodeRules from './PostcodeRules'

import { GET_MANY, useClient } from '@peracto/client'
import { useConfig } from '@peracto/peracto-config'
import { useSettings } from '@peracto/peracto-hooks'
import startCase from 'lodash/startCase'

export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
]

const DEFAULT_SHIPPING_SERVICE_TYPES = [
    { label: 'Delivery', value: 'delivery' },
    { label: 'Click and Collect', value: 'click_and_collect' },
]

const ShippingServiceForm = ({ mode = MODE_EDIT, onDuplicate = () => {}, values, ...props }) => {

    const [countries, setCountries] = useState([])
    const [loading, setLoading] = useState(true)
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false)

    const { client } = useClient()
    const config = useConfig()
    const { shippingServices } = config.get('features', {})

    const { values: p_values } = useSettings()

    const shippingServiceTypes =
        p_values?.types?.map(type => ({
            label: startCase(type.replaceAll('_', ' ')),
            value: type,
        })) || DEFAULT_SHIPPING_SERVICE_TYPES

    const fetchCountries = useCallback(async () => {
        try {
            const { data } = await client(GET_MANY, 'countries', {
                id: 'countries',
                validForBilling: true,
            })

            setCountries(data)

            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }, [client])

    useEffect(() => {
        fetchCountries()
    }, [fetchCountries])

    if (loading) {
        return (
            <div className="card">
                <div className="card-body">Loading...</div>
            </div>
        )
    }

    return (
        <>
            {mode === MODE_EDIT && (
                <FormActions>
                    <button
                        style={{border: 'none', background: 'none'}}
                        onClick={() => setShowDuplicateDialog(true)}>
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />
                        Duplicate
                    </button>
                </FormActions>
            )}

            <Form
                autoComplete="off"
                values={{
                    cutoffTime: null,
                    ...values,
                    deliveryDays: {
                        day_0: !!values?.deliveryDays?.day_0,
                        day_1: !!values?.deliveryDays?.day_1,
                        day_2: !!values?.deliveryDays?.day_2,
                        day_3: !!values?.deliveryDays?.day_3,
                        day_4: !!values?.deliveryDays?.day_4,
                        day_5: !!values?.deliveryDays?.day_5,
                        day_6: !!values?.deliveryDays?.day_6,
                    },
                }}
                {...props}
            >
                <Group key="core-data" id="core-data" name="Core Data">
                    <Input
                        name="name"
                        label="Service Name"
                        required
                        help="The service name will be shown to users when their basket / items are eligable for the service."
                    />

                    <Textarea
                        name="description"
                        label="Service Description"
                        help="The Service description is available to provide additional information not included within the Service name."
                    />

                    <Input
                        name="code"
                        label="Service Code"
                        required
                        help="Service code identifies the service and must be unique."
                    />

                    <Select
                        name="type"
                        label="Service Type"
                        options={shippingServiceTypes}
                        data-testid="shipping-service-type"
                    />

                    <Input
                        name="displayOrder"
                        label="Display Order"
                        help="Services as displayed in ascending order."
                    />

                    <Select
                        name="status"
                        label="Status"
                        placeholder="Please select..."
                        options={statusOptions}
                    />
                </Group>

                <Group
                    key="countries-default-charges"
                    id="countries-default-charges"
                    name="Countries and Default Charges"
                >
                    <p className="help-text">
                        Default charges are set by country. Postcode specific rules and surcharges
                        are always added to this cost (which can be 0)
                    </p>

                    <DeliveryCosts name="costs" countries={countries} />
                </Group>

                {shippingServices?.deliveryDays && (
                    <Group
                        key="service-availability"
                        id="service-availability"
                        name="Service Availability"
                    >
                        <p className="help-text">
                            Which delivery days is this Service <u>available</u> for?
                        </p>

                        <Checkbox name="deliveryDays.day_0" label="Sunday" />
                        <Checkbox name="deliveryDays.day_1" label="Monday" />
                        <Checkbox name="deliveryDays.day_2" label="Tuesday" />
                        <Checkbox name="deliveryDays.day_3" label="Wednesday" />
                        <Checkbox name="deliveryDays.day_4" label="Thursday" />
                        <Checkbox name="deliveryDays.day_5" label="Friday" />
                        <Checkbox name="deliveryDays.day_6" label="Saturday" />

                        <p className="help-text mt-4">
                            Which delivery dates is this Service <u>available</u> for?
                        </p>

                        <DateManagement name="dateInclusions" type="inclusion" />

                        <p className="help-text mt-4">
                            Which delivery dates is this Service <u>unavailable</u> for?
                        </p>

                        <DateManagement name="dateExclusions" type="exclusion" />

                        <Input
                            name="leadTimeDays"
                            label="Lead Time Days"
                            help="The number of days between an order being placed and the earliest available delivery slot for the Shipping Service."
                            required
                            type="number"
                            min={0}
                        />

                        <Input
                            name="cutoffTime"
                            label="Cut Off Time"
                            help="The last time *of any day* that an order can be placed in order to be eligible for a future delivery slot for the Shipping Service."
                            type="time"
                            step={1}
                        />
                    </Group>
                )}

                <Group key="postcode-rules" id="postcode-rules" name="Postcode Rules">
                    <p className="help-text">
                        Use postcode rules to control service availability by Postcode Outcode and
                        Sector.
                    </p>

                    <Radio
                        name="postcodeRulesMode"
                        label=""
                        required
                        options={[
                            {
                                label: 'This service is available to all postcodes',
                                value: 'all',
                            },
                            {
                                label: 'This service is not available to the following postcodes',
                                value: 'excluded',
                            },
                            {
                                label: 'This service is only available to the following postcodes',
                                value: 'included',
                            },
                        ]}
                    />

                    <Field name="postcodeRulesMode">
                        {({ field }) => (
                            <>
                                {field.value !== 'all' && (
                                    <>
                                        <div className="form-group">
                                            {/* We only support UK at the moment but this list will be built using the countries selected */}
                                            <hr />
                                            <label>United Kingdom</label>
                                            <PostcodeRules name="postcodeRules" />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </Field>
                </Group>

                <Group
                    key="uk-postcode-charges"
                    id="uk-postcode-charges"
                    name="United Kingdom Postcode Charges"
                >
                    <p className="help-text">
                        If your delivery charges differ by postcode, manage them by exception here.
                    </p>

                    <PostcodeCharges name="postcodeCosts" />
                </Group>

                {shippingServices?.conditions && (
                    <Group key="conditions" id="conditions" name="Conditions">
                        <p className="help-text">
                            Use conditions to control the Shipping Services that are made available
                            for a customer basket.
                        </p>

                        <Conditions name="conditions" />
                    </Group>
                )}
            </Form>

            <Modal
                isVisible={showDuplicateDialog}
                title="Duplicate Shipping Service"
                close={() => setShowDuplicateDialog(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Close',
                        action: () => setShowDuplicateDialog(false),
                    },
                    {
                        type: 'btn-success',
                        text: 'Duplicate Shipping Service',
                        action: () => {
                            onDuplicate(values)
                            setShowDuplicateDialog(false)
                        },
                    },
                ]}
            >
                <FontAwesomeIcon icon={faInfoCircle} size="4x" className="d-block mb-4" />
                Are you sure you would like to duplicate this shipping service?
            </Modal>
        </>
    )
}

ShippingServiceForm.displayName = 'ShippingServiceForm'

ShippingServiceForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default ShippingServiceForm
